// extracted by mini-css-extract-plugin
export var wrapper = "Post-module--wrapper--3q-7f";
export var container = "Post-module--container--c4CHC";
export var body = "Post-module--body--1RqQX";
export var divShortVertical = "Post-module--div-short-vertical--ZyJTe";
export var divShort = "Post-module--div-short--kS5p1";
export var divFull = "Post-module--div-full--3u2v8";
export var themeDivPattern = "Post-module--theme-div-pattern--3h-Kr";
export var logoContainer = "Post-module--logo-container--2R89A";
export var randomShapeA = "Post-module--random-shape-a--sutVp";
export var randomShapeB = "Post-module--random-shape-b--2RQSu";
export var post = "Post-module--post--3OofA";
export var title = "Post-module--title--2qqgo";
export var date = "Post-module--date--1S5Ur";
export var excerpt = "Post-module--excerpt--V2BWN";
export var tags = "Post-module--tags--2g5lJ";
export var postFooter = "Post-module--post-footer--218Xb";
export var subFooter = "Post-module--sub-footer--3HpnO";
export var previous = "Post-module--previous--SnToi";
export var next = "Post-module--next--3kIOc";